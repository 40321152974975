<template>
<div style="width:100%">
<div class="text-center" v-if="loandingQR">
  <br><br>
  <v-progress-circular :size="150" :width="7" :color="colores.primario" indeterminate></v-progress-circular>
  <br><br><br><br>
</div>
<div v-if="registro">
  <p v-if="cerrar">{{ endInterval }}</p>
    <v-row no-gutters>
      <v-col cols="12" class="text-center">
        <span :style="[{'color': colores.black}, {'font-size': '20px' }]"><b>DATOS DE LA QR</b></span>
      </v-col>
    </v-row>
    <br>
    <lecturaHead :registro="registro" :colores="colores" :dataReplica="dataReplica"></lecturaHead>
    <br>
    <lecturaSolicitante :registro="registro" :colores="colores"></lecturaSolicitante>
    <br>
    <lecturaCliente :registro="registro" :colores="colores"></lecturaCliente>
    <br>
    <lecturaProducto :registro="registro" :colores="colores"></lecturaProducto>
    <br>
    <lecturaQr :registro="registro" :colores="colores" :session="session" :dataReplica="dataReplica"></lecturaQr>
    <br>

    <lecturaEscalamientos v-if="registro.escalamientos.length>0" :registro="registro" :colores="colores"></lecturaEscalamientos>
    <div v-if="(((registro.subestado_id === 1 || registro.subestado_id === 2 || registro.subestado_id === 3)  &&  (registro.gesdato==null || registro.gesdato.correo==='')) && session.area !== 16)">
      <v-form ref="form_confirmar">
        <br>
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <confirmacionDatos :form_confirmar="form_confirmar" :rules="rules" :colores="colores" :messagesError="messagesError"></confirmacionDatos>
            </v-col>
          </v-row>
          <br>
          <v-row no-gutters v-if="this.session.id === this.idEscalado" >
            <v-col cols="12" class="text-center">
              <v-btn @click="validar" block large :color="colores.black" dark>CONFIRMAR INFORMACIÓN</v-btn>
            </v-col>
          </v-row>
      </v-form>
    </div>
    <div v-else>
      <br>
      <lecturaOdt v-if="registro.gesodt" :registro="registro" :colores="colores"></lecturaOdt>
      <br>
      <lecturaConfirmacion :form_confirmar="form_confirmar" :registro="registro" :colores="colores" :session="session" :rules="rules"></lecturaConfirmacion>
      <br>
      <formEscalamientos v-if="(registro.fechacerrado  === null && (registro.subestado_id === 1 || registro.subestado_id === 2 || registro.subestado_id === 3 || registro.subestado_id === 10) && session.area !== 16)" :form_otro="form_otro" :registro="registro" :rules="rules" :session="session" :gestion_id="gestion_id" :form_odt="form_odt" :form_objs="form_objs" :colores="colores" :items="items" @update_registro="actualizar_registro"></formEscalamientos>
    </div>
    <lecturaCierre v-if="registro.fechacerrado !== null && registro.gesduplicado.marcacion_id != 485" :registro="registro" :colores="colores"></lecturaCierre>
    <lecturaCierreReplica v-if="((dataReplicaCerrado) && (registro.gesduplicado.marcacion_id != 485 && registro.subestado_id === 6))" :registro="registro" :colores="colores" :dataReplica="dataReplica"></lecturaCierreReplica>
    <br><br>
    <formEscalamientosReplica v-if="dataReplicaAbierta && this.session.id === this.idEscalado" :dataReplica="dataReplica" :form_otro="form_otro" :registro="registro" :rules="rules" :session="session" :gestion_id="gestion_id" :form_odt="form_odt" :form_objs="form_objs" :colores="colores" :items="items" @update_registro="actualizar_registro"></formEscalamientosReplica>
    <br><br>
    <lecturaHistorial v-if="registro.geslogs.length>0 || registro.gescierre" :registro="registro" :colores="colores"></lecturaHistorial>
    <br><br>
    <br><br>
    <lecturaHistorialCierre v-if="registro.emailstatus.length>0 || registro.gescierre" :registro="registro" :colores="colores"></lecturaHistorialCierre>
    <br><br>
    <br><br>
    <formCalidad :session="session" :gestion_id="gestion_id" :rules="rules" :items="items" :colores="colores" @lecturaCalidadEmit="estadoLectura=1"></formCalidad>
    <br><br>
    <lecturaCalidad :estadoLectura="estadoLectura" :session="session" :gestion_id="gestion_id" :rules="rules" :items="items" :colores="colores"></lecturaCalidad>
    <br><br>
  </div>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>

  <br><br><br>
</div>
</template>

<script>
import recGestiones from '@/js/rec_gestiones.js'
import recFunciones from '@/js/rec_funciones.js'
// import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
// import lecturaHead from '@/components/crm/lectura_headComponent.vue'
// import lecturaSolicitante from '@/components/crm/lectura_solicitanteComponent.vue'
// import lecturaCliente from '@/components/crm/lectura_clienteComponent.vue'
// import lecturaProducto from '@/components/crm/lectura_productoComponent.vue'
// import lecturaQr from '@/components/crm/lectura_qrComponent.vue'
// import lecturaEscalamientos from '@/components/crm/lectura_escalamientosComponent.vue'
// import lecturaCierre from '@/components/crm/lectura_cierreComponent.vue'
// import lecturaConfirmacion from '@/components/crm/lectura_confirmacionComponent.vue'
// import lecturaHistorial from '@/components/crm/lectura_historialesComponent.vue'
// import formEscalamientos from '@/components/crm/form_escalamientoComponent.vue'
// import confirmacionDatos from '@/components/crm/form_confirmacion_datosComponent.vue'

export default {
  name: 'crm_lecturaComponent',
  components: {
    lecturaHead: () => import('@/components/crm/lectura_headComponent.vue'),
    lecturaSolicitante: () => import('@/components/crm/lectura_solicitanteComponent.vue'),
    lecturaCliente: () => import('@/components/crm/lectura_clienteComponent.vue'),
    lecturaProducto: () => import('@/components/crm/lectura_productoComponent.vue'),
    lecturaQr: () => import('@/components/crm/lectura_qrComponent.vue'),
    lecturaEscalamientos: () => import('@/components/crm/lectura_escalamientosComponent.vue'),
    lecturaConfirmacion: () => import('@/components/crm/lectura_confirmacionComponent.vue'),
    lecturaHistorial: () => import('@/components/crm/lectura_historialesComponent.vue'),
    lecturaHistorialCierre: () => import('@/components/crm/lectura_historialesCierresComponent.vue'),
    lecturaCierre: () => import('@/components/crm/lectura_cierreComponent.vue'),
    lecturaCierreReplica: () => import('@/components/crm/lectura_cierre_replicaComponent.vue'),
    lecturaOdt: () => import('@/components/crm/lectura_odtComponent.vue'),
    confirmacionDatos: () => import('@/components/crm/form_confirmacion_datosComponent.vue'),
    formEscalamientos: () => import('@/components/crm/form_escalamientoComponent.vue'),
    formEscalamientosReplica: () => import('@/components/crm/form_escalamientoReplicaComponent.vue'),
    mensajesSave: () => import('@/components/widgets/mensajes_saveComponent.vue'),
    lecturaCalidad: () => import('@/components/crm/lectura_calidadComponent.vue'),
    formCalidad: () => import('@/components/crm/form_calidadComponent.vue')
  },
  mixins: [recGestiones, recFunciones],
  props: ['colores', 'items', 'token', 'gestion_id', 'session', 'cerrar'],
  data: () => ({
    temporaryEmails: [],
    state: true,
    updated: false,
    esperar: false,
    messagesError: null,
    structure: /^.+@.+\../,
    idSetIntervalQR: null,
    showEscalamiento: false,
    loandingQR: false,
    registro: null,
    rules: null,
    idEscalado: '',
    form_confirmar: { gestion_id: 0, correo: '', codigo_sfc: '', codigo_dcf: '', codigo_tutela: '', codigo_interno: '', proteccion_dp: false },
    form_odt: { gestion_id: 0, id_usuario: 0, razon_id: 0, detalle_id: 0 },
    form_objs: { keyform: 0, gestion_id: 0, id_usuario: 0, items_escalamiento: [], descripcion: '' },
    form_otro: { gestion_id: 0, geslog_id: 0, keyform: 0, correo: '', descripcion: '', correoenvio_id: 0, items_archivos: [] },
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: [],
    dataReplica: [],
    dataReplicaAbierta: false,
    dataReplicaCerrado: false,
    estadoLectura: 0
  }),
  watch: {
    token: {
      immediate: true,
      handler (newVal, oldVal) {
        this.rec_gestion_escalamientos_inicializar()
        this.rec_gestion_por_id(this.gestion_id)
        this.startInterval()
      }
    }
  },
  created () {
    this.rules = this.$store.getters.getRules
  },
  methods: {
    validar () {
      if (this.$refs.form_confirmar.validate()) {
        this.messagesError = null
        var tempoCorreo = this.form_confirmar.correo.replace(/\s+/g, '')
        this.form_confirmar.correo = tempoCorreo
        this.temporaryEmails = this.form_confirmar.correo.split(';')
        for (let i = 0; i < this.temporaryEmails.length; i++) {
          if (!this.structure.test(this.temporaryEmails[i]) && this.temporaryEmails[i].length > 0) {
            this.state = false
            break
          }
        }
        if (!this.state) {
          this.messagesError = 'Verifique los correos'
          setTimeout(() => {
            this.messagesError = null
            this.state = true
          }, 5000)
        } else {
          this.dialogo.pregunta = true
        }
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestion_confirmar_datos('Crear')
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    },
    actualizar_registro (pRegistro) {
      this.registro = pRegistro
    },
    startInterval () {
      this.idSetIntervalQR = setInterval(() => {
        // 10000 - 10 segundos
        // 60000 - 60 segundos
        // 120000 - 2 minutos
        // 240000 - 4 minutos
        for (let i = 0; i < this.registro.escalamientos.length; i++) {
          if (this.registro.escalamientos[i].fecha_cerrado === null) {
            this.idEscalado = this.registro.escalamientos[i].id_escaladoa
          }
        }
        this.rec_gestion_noreload_por_id(this.gestion_id)
      }, 30000)
    },
    estadoReplica (registro) {
      this.dataReplica = []
      this.dataReplicaAbierta = false
      this.dataReplicaCerrado = false
      console.log(registro.replicas)
      for (let i = 0; i < registro.replicas.length; i++) {
        if (registro.replicas.length > 0) {
          // this.dataReplica[i] = registro.replicas[i]
          let existe = 0
          for (let j = 0; j < this.dataReplica.length; j++) {
            if (registro.replicas[i].created_at.substr(0, 10) === this.dataReplica[j].created_at.substr(0, 10) && registro.replicas[i].argumento_replica === this.dataReplica[j].argumento_replica) {
              existe = 1
            }
          }
          if (existe === 0) {
            this.dataReplica.push(registro.replicas[i])
            console.log('nuevo')
            console.log(this.dataReplica)
          }
        }
      }
      for (let index = 0; index < this.dataReplica.length; index++) {
        const element = this.dataReplica[index]
        if (element.fechacerrado === null) {
          this.dataReplicaAbierta = true
        }
        if (element.fechacerrado !== null) {
          this.dataReplicaCerrado = true
        }
      }
    }
  },
  computed: {
    endInterval: function () {
      clearTimeout(this.idSetIntervalQR)
      return null
    }
  }

}
</script>
